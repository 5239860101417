// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-about-jsx": () => import("./../../../src/pages/about.jsx" /* webpackChunkName: "component---src-pages-about-jsx" */),
  "component---src-pages-contact-jsx": () => import("./../../../src/pages/contact.jsx" /* webpackChunkName: "component---src-pages-contact-jsx" */),
  "component---src-pages-fiji-baat-jsx": () => import("./../../../src/pages/fiji-baat.jsx" /* webpackChunkName: "component---src-pages-fiji-baat-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-projects-jsx": () => import("./../../../src/pages/projects.jsx" /* webpackChunkName: "component---src-pages-projects-jsx" */),
  "component---src-pages-social-media-jsx": () => import("./../../../src/pages/social-media.jsx" /* webpackChunkName: "component---src-pages-social-media-jsx" */),
  "component---src-pages-support-jsx": () => import("./../../../src/pages/support.jsx" /* webpackChunkName: "component---src-pages-support-jsx" */),
  "component---src-pages-who-we-are-jsx": () => import("./../../../src/pages/who-we-are.jsx" /* webpackChunkName: "component---src-pages-who-we-are-jsx" */),
  "component---src-templates-episode-jsx": () => import("./../../../src/templates/episode.jsx" /* webpackChunkName: "component---src-templates-episode-jsx" */),
  "component---src-templates-project-jsx": () => import("./../../../src/templates/project.jsx" /* webpackChunkName: "component---src-templates-project-jsx" */)
}

